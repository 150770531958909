// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-annual-reports-tsx": () => import("./../../../src/pages/annual-reports.tsx" /* webpackChunkName: "component---src-pages-annual-reports-tsx" */),
  "component---src-pages-help-tsx": () => import("./../../../src/pages/help.tsx" /* webpackChunkName: "component---src-pages-help-tsx" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-templates-article-listing-page-tsx": () => import("./../../../src/templates/ArticleListingPage.tsx" /* webpackChunkName: "component---src-templates-article-listing-page-tsx" */),
  "component---src-templates-article-page-tsx": () => import("./../../../src/templates/ArticlePage.tsx" /* webpackChunkName: "component---src-templates-article-page-tsx" */),
  "component---src-templates-experiment-listing-page-tsx": () => import("./../../../src/templates/ExperimentListingPage.tsx" /* webpackChunkName: "component---src-templates-experiment-listing-page-tsx" */),
  "component---src-templates-experiment-page-tsx": () => import("./../../../src/templates/ExperimentPage.tsx" /* webpackChunkName: "component---src-templates-experiment-page-tsx" */),
  "component---src-templates-foundation-listing-page-tsx": () => import("./../../../src/templates/FoundationListingPage.tsx" /* webpackChunkName: "component---src-templates-foundation-listing-page-tsx" */),
  "component---src-templates-foundation-partnership-page-tsx": () => import("./../../../src/templates/FoundationPartnershipPage.tsx" /* webpackChunkName: "component---src-templates-foundation-partnership-page-tsx" */),
  "component---src-templates-histology-listing-page-tsx": () => import("./../../../src/templates/HistologyListingPage.tsx" /* webpackChunkName: "component---src-templates-histology-listing-page-tsx" */),
  "component---src-templates-histology-page-tsx": () => import("./../../../src/templates/HistologyPage.tsx" /* webpackChunkName: "component---src-templates-histology-page-tsx" */),
  "component---src-templates-home-page-tsx": () => import("./../../../src/templates/HomePage.tsx" /* webpackChunkName: "component---src-templates-home-page-tsx" */),
  "component---src-templates-informatics-portals-page-tsx": () => import("./../../../src/templates/InformaticsPortalsPage.tsx" /* webpackChunkName: "component---src-templates-informatics-portals-page-tsx" */),
  "component---src-templates-member-institution-listing-page-tsx": () => import("./../../../src/templates/MemberInstitutionListingPage.tsx" /* webpackChunkName: "component---src-templates-member-institution-listing-page-tsx" */),
  "component---src-templates-member-institution-page-tsx": () => import("./../../../src/templates/MemberInstitutionPage.tsx" /* webpackChunkName: "component---src-templates-member-institution-page-tsx" */),
  "component---src-templates-person-listing-page-tsx": () => import("./../../../src/templates/PersonListingPage.tsx" /* webpackChunkName: "component---src-templates-person-listing-page-tsx" */),
  "component---src-templates-person-page-tsx": () => import("./../../../src/templates/PersonPage.tsx" /* webpackChunkName: "component---src-templates-person-page-tsx" */),
  "component---src-templates-presentation-listing-page-tsx": () => import("./../../../src/templates/PresentationListingPage.tsx" /* webpackChunkName: "component---src-templates-presentation-listing-page-tsx" */),
  "component---src-templates-presentation-page-tsx": () => import("./../../../src/templates/PresentationPage.tsx" /* webpackChunkName: "component---src-templates-presentation-page-tsx" */),
  "component---src-templates-publication-listing-page-tsx": () => import("./../../../src/templates/PublicationListingPage.tsx" /* webpackChunkName: "component---src-templates-publication-listing-page-tsx" */),
  "component---src-templates-publication-page-tsx": () => import("./../../../src/templates/PublicationPage.tsx" /* webpackChunkName: "component---src-templates-publication-page-tsx" */),
  "component---src-templates-research-partnership-listing-page-tsx": () => import("./../../../src/templates/ResearchPartnershipListingPage.tsx" /* webpackChunkName: "component---src-templates-research-partnership-listing-page-tsx" */),
  "component---src-templates-research-partnership-page-tsx": () => import("./../../../src/templates/ResearchPartnershipPage.tsx" /* webpackChunkName: "component---src-templates-research-partnership-page-tsx" */),
  "component---src-templates-standard-page-tsx": () => import("./../../../src/templates/StandardPage.tsx" /* webpackChunkName: "component---src-templates-standard-page-tsx" */)
}

