import React from "react";
import { RecoilRoot } from "recoil";
import "./src/css/fonts/jaf-domus/style.css";
import "./src/css/fonts/source-sans-pro-v13-latin/style.css";
import "./src/css/style.css";

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  if (location.search) {
    return false;
  }

  return true;
};

export const wrapPageElement = ({ element, props }) => (
  <RecoilRoot {...props}>{element}</RecoilRoot>
);
